import "./MadeInGermanyBadge.css"
import {CSSProperties} from "react";

function MadeInGermanyBadge(props: { style?: CSSProperties }) {
    return (
        <div style={props.style}>
            <div style={{width: "120px", height: "120px"}}>
                <div id={"badge-content"} className={"clip-animation"} style={{position: "relative"}}>
                    <div id={"badge-flag"} style={{position: "absolute"}}>
                        <div style={{width: "120px", height: "40px", backgroundColor: "black"}}></div>
                        <div style={{width: "120px", height: "35px", backgroundColor: "rgb(221,0,0)"}}></div>
                        <div style={{width: "120px", height: "45px", backgroundColor: "rgb(255,206,0)"}}></div>
                    </div>
                    <div style={{position: "absolute", zIndex: 1, color: "white", width: "120px", textAlign: "center", top: "42px", fontWeight: 800, fontSize: "14px"}} className={"noselect"} id={"badge-label"}>MADE IN<br />GERMANY</div>
                </div>
            </div>
        </div>
    )
}

export default MadeInGermanyBadge;