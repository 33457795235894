import {PAGE_NAME} from "../constants";
import React from "react";
import InfoFooter from "../comp/InfoFooter";

const faq: { question: string, answer: string }[] = [
    {
        question: "What is a pre-order, and why do you offer it?",
        answer: "A pre-order allows you to express your interest in our Bavarian beer products before they become available. It helps us gauge demand and ensure we have sufficient stock for our customers."
    },
    {
        question: "How do I get my order?",
        answer: "You will have to pick it up in Lisbon. We will let you know where exactly as soon as possible, if you don't like it we will refund you. Additionally, we hope to offer delivery as well. If you are interested in that send us an email at cheers@bierbier.pt."
    },
    {
        question: "Is placing a pre-order binding in any way?",
        answer: "No, placing a pre-order is not binding for you or for us. It serves as an expression of interest, and you are not obligated to make a purchase."
    },
    {
        question: "How will I be notified about the status of my pre-order?",
        answer: "We will contact you within 10 days of your pre-order to provide an update on availability and pricing."
    },
    {
        question: "Can I cancel my pre-order if I change my mind?",
        answer: "Yes, of course. If you simply don't pay on time, we will cancel your order automatically. There are no cancellation fees."
    },
    {
        question: "How can I pay for my order?",
        answer: "We accept various payment methods, including credit/debit cards. Specific payment details will be provided when we confirm your order. Payments will be handled securely by stripe."
    },
]

function FaqPage() {
    return (
        <div className={"page"}>
            <div className={"page-content"}>
                <h1>{PAGE_NAME}</h1>
                { faq.map(({question, answer}, index) =>
                    <div key={index}>
                        <h4>{question}</h4>
                        <p>{answer}</p>
                    </div>
                )}
                <div style={{marginTop: "36px"}}>
                    <div><a href={"/"}>Go to homepage</a></div>
                </div>
                <InfoFooter showFaq={false} />
            </div>
        </div>
    )
}

export default FaqPage;