import "./VoucherComponent.css";
import axios from "axios";
import {Voucher} from "../pages/ShoppingPage";
import React, {KeyboardEventHandler, useEffect} from "react";
import {useSearchParams} from "react-router-dom";

function VoucherComponent (props: { onVoucherApplied: (v: Voucher | undefined) => any }) {
    const [searchParams] = useSearchParams();
    const paramVoucher = searchParams.get("voucher");

    const [code, setCode] = React.useState<string>();
    const [voucher, setVoucher] = React.useState<Voucher>();
    const [error, setError] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false);
    async function fetchVoucher (code: string | undefined) {
        if (isLoading) return;
        if (!code) return;
        setIsLoading(true);
        let data = JSON.stringify({ code: code.trim().toUpperCase() });
        let config = {
            method: 'post',
            url: 'https://api.retool.com/v1/workflows/295d8ed3-c18d-4589-843b-3c69f349bc09/startTrigger?workflowApiKey=retool_wk_27cda99f87634daf97ba1af049b787d3',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };
        axios.request(config).then(res => {
            setIsLoading(false);
            const voucher = res.data as unknown as Voucher;
            setVoucher(voucher);
            props.onVoucherApplied(voucher);
            setError(false);
        }).catch(() => {
            setIsLoading(false);
            setVoucher(undefined);
            setError(true);
        });
    }
    useEffect(() => {
        fetchVoucher(paramVoucher ?? undefined).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const inputKeyPressHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            fetchVoucher(code).then();
        }
    }
    return (
        <div style={{width: "100%", boxSizing: "border-box", padding: "0px 32px", position: "relative"}}>
            { isLoading ? <div style={{position: "absolute", top: "0", right: "0", left: "0", bottom: "0", backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 99}}>
                <div className={"loader"}></div>
            </div> : null }
            { !voucher ? <p style={{width: "100%", textAlign: "center"}}>Do you have a code? Enter it here:</p> : null }
            { !voucher ? <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                <input value={paramVoucher ?? undefined} id={"input-voucher"} onChange={(e) => setCode(e.target.value)} onKeyDown={inputKeyPressHandler} type={"text"} name={"voucher"} placeholder={"voucher code"}/>
                <div className={ code ? "" : "disabled"} id={"button-apply-voucher"} onClick={() => fetchVoucher(code)}>apply</div>
            </div> : null }
            { error ? <div style={{width: "100%", color: "red", textAlign: "center", marginTop: "6px"}}>the entered code is not valid.</div> : null }
            { voucher ? <div style={{width: "100%", textAlign: "center", backgroundColor: "rgba(35,131,29,0.1)", borderRadius: "12px", padding: "8px 16px", boxSizing: "border-box"}}>Code <b>&quot;{voucher.code}&quot;</b> applied!</div> : null}
        </div>
    )
}

export default VoucherComponent;