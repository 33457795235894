import React from "react";
import {PAGE_NAME} from "../constants";

function InfoFooter(props: { showFaq?: boolean }) {
    return (
        <div style={{marginTop: "100px", marginBottom: "80px", width: "100%", fontSize: "13pt"}}>
            <p>Have any questions? <a href={"mailto:cheers@bierbier.pt"}>Contact us</a>{ props?.showFaq ?? true ? <span> or check our <a href={"/faq"}>FAQ</a></span> : null }</p>
            <p style={{opacity: 0.65}}>{PAGE_NAME} is operated by:<br/>Moritz Bruder and Arne De Baets<br/>Rua da Guiné 8, 2E<br/>1170-172 Lisboa, PT</p>
            <br />
            <p style={{textAlign: "center", fontSize: "18pt"}}>&#127867;</p>
        </div>
    )
}

export default InfoFooter;