import React, {useEffect} from "react";
import "./ShoppingPage.css";
import img_augustiner_hero_bottle from "../assets/augustiner_hero_bottle.webp";
import img_augustiner_hero_crate from "../assets/augustiner_hero_crate.webp";
import MadeInGermanyBadge from "../comp/MadeInGermanyBadge";
import {useNavigate} from "react-router-dom";
import QuantityPreview from "../comp/QuantityPreview";
import InfoFooter from "../comp/InfoFooter";
import {MAX_ORDER_QTY, PAGE_NAME, PRICE_CRATE} from "../constants";
import VoucherComponent from "../comp/VoucherComponent";

export interface Voucher {
    code: string;
    discount_pct_crate: number;
    discount_pct_delvery: number;
}

function ShoppingPage() {
    const [quantity, setQuantity] = React.useState(2);
    const [voucher, setVoucher] = React.useState<Voucher>();
    function incQuantity () {
        setQuantity(Math.min(quantity + 1, MAX_ORDER_QTY))
    }
    function decQuantity () {
        setQuantity(Math.max(quantity - 1, 1));
    }
    const navigate = useNavigate();
    function goToCheckout() {
        navigate(`/checkout`, { state: { quantity, voucher } })
        window.scroll({
            top: 0,
            behavior: "auto"
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', (window.pageYOffset / (document.body.offsetHeight - window.innerHeight)).toString());
        }, false);
    })

    function pricePerCrate() {
        const discountFactor = ((100 - (voucher?.discount_pct_crate ?? 0)) / 100);
        return PRICE_CRATE * discountFactor;
    }

    return (
        <div className={"page"}>
            <div className={"page-content"}>
                <h1>{PAGE_NAME}</h1>
                <p>Welcome to our site, beer lovers! We finally bring you the opportunity to enjoy delicious Bavarian beer in Lisbon &#127867;</p>
                <div className={"page-content"}>
                    <h3>The Beer</h3>
                    <div style={{width: "100%", position: "relative", height: "320px"}}>
                        <img className={"product-picture-crate"} src={img_augustiner_hero_crate} alt={"A crate of Augustiner beer."} />
                        <img className={"product-picture-bottle"} src={img_augustiner_hero_bottle} alt={"A crate of Augustiner beer."} />
                        <MadeInGermanyBadge style={{position: "absolute", top: 0, right: "30px", zIndex: 2}} />
                    </div>
                    <h4>Augustiner Lagerbier Hell</h4>
                    <small style={{textAlign: "center"}}>Price: { PRICE_CRATE !== pricePerCrate() ? <span><span className={"strikethrough-price"}>{ PRICE_CRATE }&euro;</span> </span> : null }{pricePerCrate()}&euro; per crate<br />(contains 20 bottles, {Math.round(pricePerCrate()/20*100)/100}&euro; per 500ml bottle)</small>
                    <p>Augustiner Lagerbier Hell is a classic Bavarian beer known for its golden hue and balanced taste, blending mild malt sweetness with a touch of hop bitterness. With a history dating back to 1328, it embodies the rich brewing traditions of Munich, offering a timeless and refreshing experience.</p>
                    <p>Learn more about <a href={"https://en.wikipedia.org/wiki/Augustiner-Br%C3%A4u"}>Augustiner</a> or <a href={"https://en.wikipedia.org/wiki/Helles"}>Helles</a>.</p>

                    <h3>Your Order</h3>
                    <VoucherComponent onVoucherApplied={(v) => setVoucher(v)} />
                    <div style={{padding: "32px", width: "100%", boxSizing: "border-box"}} className={"noselect"}>
                        <QuantityPreview quantity={quantity} voucher={voucher} />
                        <div id={"quantity-stepper"}>
                            <div className={"stepper-button"} style={quantity === 1 ? { opacity: 0.5, cursor: "unset", fontWeight: "unset" } : undefined} onClick={() => decQuantity()}>less</div>
                            <div className={"stepper-button"} style={quantity === MAX_ORDER_QTY ? { opacity: 0.5, cursor: "unset", fontWeight: "unset" } : undefined} onClick={() => incQuantity()}>more</div>
                        </div>
                    </div>

                    <div id={"summary-container"}>
                        <div style={{textAlign: "center"}}><b>-&nbsp;&nbsp;Order Summary&nbsp;&nbsp;-</b></div>
                        <div className={"pricing"} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div>
                                <span className={"math"}>{ quantity }x </span>
                                <span className={"price"}>
                                    { pricePerCrate() !== PRICE_CRATE ? <span><span className={"strikethrough-price"}>{PRICE_CRATE}&euro;</span>&nbsp;</span> : null}
                                    <span>{ pricePerCrate() }&euro;</span>
                                </span>
                                &nbsp;
                                <span className={"description"}>per Crate</span>
                            </div>
                            <div style={{marginTop: "8px"}}>
                                <span className={"math"}>=</span>
                                <span className={"price"}>{ Math.round(quantity * pricePerCrate() * 100) / 100 } &euro;</span>
                                &nbsp;
                                <span className={"description"}>Total</span>
                            </div>
                        </div>
                        <div id={"button-go-to-checkout"} className={"noselect"} onClick={() => goToCheckout()}>Go to checkout</div>
                    </div>

                    <InfoFooter />
                </div>
            </div>
        </div>
    );
}

export default ShoppingPage;