import React from "react";
import InfoFooter from "../comp/InfoFooter";
import {PAGE_NAME} from "../constants";

function ThankYouPage () {
    return (
        <div className={"page"}>
            <div className={"page-content"}>
                <h1>{PAGE_NAME}</h1>
                <p>Thank you for your order, we'll be in touch soon.</p>
                <a href={"/"}>To homepage.</a>
                <InfoFooter />
            </div>
        </div>
    )
}

export default ThankYouPage;