import {useLocation, useNavigate} from "react-router-dom";
import React, {FormEvent} from "react";
import axios from "axios";
import "./CheckoutPage.css"
import QuantityPreview from "../comp/QuantityPreview";
import InfoFooter from "../comp/InfoFooter";
import {DateTime} from "luxon";
import Ajv from "ajv";
import {PAGE_NAME, PRICE_CRATE} from "../constants";

const WEBHOOK_URL = "https://api.retool.com/v1/workflows/4d3cc293-bbc2-414c-af16-99941e2b59fa/startTrigger?workflowApiKey=retool_wk_73e8e6dd2eac4dba9ddc8de61aff84b3";

const ajv = new Ajv();
const inputSchema = ajv.compile({
    type: "object",
    properties: {
        quantity: { type: "integer" },
        name: { type: "string", minLength: 2 },
        email: { type: "string", pattern: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$" },
        addressLine1: { type: "string", minLength: 5 },
        zipCode: { type: "string", pattern: "^([0-9]|-| )*$" },
        nif: { type: "string", pattern: "^[0-9]{9}$", nullable: true }
    }
})

function CheckoutPage () {
    const { state } = useLocation();
    const { quantity, voucher } = state;

    const navigate = useNavigate();

    async function send(data: Record<string, any>) {
        setIsLoading(true);
        try {
            const res = await axios.post(WEBHOOK_URL, data);
            if (res.status !== 201) {
                alert(`Error. Code ${res.status}`);
            } else {
                navigate("/thank-you");
                window.scroll({ top: 0 });
            }
        } catch (e) {
            alert(`Error: ${(e as Error).message}`);
        } finally {
            setIsLoading(false);
        }
    }
    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        await send(getInputBody());
    }
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [addressLine1, setAddressLine1] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [city, setCity] = React.useState("");
    const [nif, setNif] = React.useState("");
    const [ageConfirmed, setAgeConfirmed] = React.useState(false);
    function getInputBody () {
        const res = { quantity, name, email, addressLine1, zipCode, city, nif, voucher: voucher?.code };
        for (const key of Object.keys(res)) {
            // @ts-ignore
            if(res[key] === "") res[key] = null;
        }
        return res;
    }
    function canSend(): boolean {
        return inputSchema(getInputBody()) && ageConfirmed;
    }
    function pricePerCrate() {
        const discountFactor = ((100 - (voucher?.discount_pct_crate ?? 0)) / 100);
        return PRICE_CRATE * discountFactor;
    }
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <div className={"page"}>
            <div className={"page-content"}>
                <h1>{PAGE_NAME}</h1>
                <div id={"order-summary-container"}>
                    <h4 style={{textAlign: "center", marginBottom: "0px"}}>-&nbsp;&nbsp;Order Summary&nbsp;&nbsp;-</h4>
                    <QuantityPreview quantity={quantity} voucher={voucher} />
                    <div style={{textAlign: "center", marginTop: "12px"}}>
                        <b>Price: { pricePerCrate() !== PRICE_CRATE ? <span className={"strikethrough-price"}>{quantity * PRICE_CRATE}&euro;</span> : null } {quantity * pricePerCrate()}&euro;</b>
                    </div>
                    <div style={{textAlign: "center", fontSize: "12pt", marginBottom: "8px"}}>(incl. VAT, no deposit)</div>
                </div>
                <form onSubmit={handleSubmit} id={"customer-form"} style={{position: "relative"}}>
                    <h4>Contact Information</h4>
                    <input disabled={isLoading} autoComplete={"off"} type={"hidden"} value={quantity} />
                    <input disabled={isLoading} autoComplete={"on"} name={"name"} type={"text"} placeholder={"your name or company name"} onChange={(e) => setName(e.target.value)} />
                    <input disabled={isLoading} autoComplete={"on"} name={"email"} type={"text"} placeholder={"email"} onChange={(e) => setEmail(e.target.value)} />
                    <input disabled={isLoading} autoComplete={"on"} name={"street-address"} type={"text"} placeholder={"address line 1 (include apt no.)"} onChange={(e) => setAddressLine1(e.target.value)} />
                    <input disabled={isLoading} autoComplete={"on"} name={"postal-code"} type={"text"} placeholder={"zip code"} onChange={(e) => setZipCode(e.target.value)} />
                    <input disabled={isLoading} autoComplete={"on"} name={"city"} type={"text"} placeholder={"city"} onChange={(e) => setCity(e.target.value)} />
                    <input disabled={isLoading} autoComplete={"on"} name={"tax-id"} type={"text"} placeholder={"NIF (optional)"} onChange={(e) => setNif(e.target.value)} />
                    <div style={{fontSize: "14pt", display: "flex", flexDirection: "row", columnGap: "8px", marginTop: "8px"}}>
                        <input type={"checkbox"} name={"confirm_min_age"} onChange={(e) => setAgeConfirmed(e.target.checked)} />
                        <div>I confirm that I am at least 18 years of age at the time of purchase. <span>(This will be verified at handover)</span></div>
                    </div>
                    <input type={"submit"} value={"Place pre-order"} style={{marginTop: "24px"}} disabled={isLoading || !canSend()}/>
                    <div id={"loading-overlay"} style={{opacity: isLoading ? 1 : 0, display: !isLoading ? "none" : undefined}}>
                        <div className={"loader"}></div>
                    </div>
                </form>
                <div style={{width: "100%", marginTop: "16px"}}>
                    <h4>Important! (please read this)</h4>
                    <p>This is a pre-order. Availability will be established next week and we will get back to you soon with an update.</p>
                    <p>Here's the process:</p>
                    <div className={"process-step"}><div className={"number"}>1</div> You place this pre-order.</div>
                    <div className={"process-step"}><div className={"number"}>2</div> We check availability and get back to you within 10 days (before {DateTime.now().plus({ days: 10 }).toFormat("dd. MMM")}).</div>
                    <div className={"process-step"}><div className={"number"}>3</div> You pick up your order at our storage location in Lisbon.</div>
                </div>

                <p>Want to have it delivered? Send us an email after placing your pre-order and we will try to make it happen &#128521;</p>

                <p style={{opacity: 0.65, marginTop: "64px"}}>Thank you for your pre-order &#129782;</p>

                <InfoFooter />
            </div>
        </div>
    )
}

export default CheckoutPage;