import {CSSTransition, TransitionGroup} from "react-transition-group";
import img_augustiner_mini from "../assets/augustiner_mini.webp";
import React from "react";
import "./QuantityPreview.css";
import {Voucher} from "../pages/ShoppingPage";
import {PRICE_CRATE} from "../constants";



function QuantityPreview (props: { quantity: number, voucher?: Voucher }) {
    function pricePerCrate() {
        const discountFactor = ((100 - (props.voucher?.discount_pct_crate ?? 0)) / 100);
        return PRICE_CRATE * discountFactor;
    }
    return (
        <div style={{display: "flex", flexDirection: "row", columnGap: "24px", justifyContent: "center", flexWrap: "wrap"}}>
            <TransitionGroup id={"crates-visualization-container"} style={{width: (Math.ceil(props.quantity / 4) * 50 + 16) + "px", justifyContent: props.quantity < 4 ? "center" : "end"}}>
                { Array.from({length: props.quantity}).map((_, i) => (
                    <CSSTransition key={i}
                                   timeout={120}
                                   classNames="item">
                        <img className={"item"} style={{zIndex: -i}} src={img_augustiner_mini} alt={"1 crate"} />
                    </CSSTransition>
                )) }
            </TransitionGroup>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "200px", padding: "8px"}}>
                <div style={{ fontSize: "16pt", fontWeight: 600, opacity: 0.65 }}>{props.quantity} Crate{props.quantity > 1 ? "s" : ""}</div>
                <div><span>{props.quantity * 20} bottles</span></div>
                <div style={{color: "#2a9323", textAlign: "center", marginTop: "12px"}}><small><b>only { Math.round(pricePerCrate() / 20 * 100) / 100 }€ per 500ml bottle!</b></small></div>
            </div>
        </div>
    )
}

export default QuantityPreview;